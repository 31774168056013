/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { T } from 'react-translator-component';
import DataTable from 'react-data-table-component';
import Switch from 'react-switch';
import ValidForm from 'react-valid-form-component';
import useFetch from 'react-use-fetch-hooks';
import Select from 'react-select';

import { Loading } from 'react-loading-ui';
import { Card, Confirm, Notify } from '../../components'; // Notify eklenecek.
import { Modal } from '../../components/Modal';
import { DataTableColumns } from '../../config/DataTableColumns';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';

export const UserRole = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});
  const [common, setCommon] = useState({});
  const [switchStatus, setSwitchStatus] = useState(true);
  const [userSelected, setUserSelected] = useState('');
  const [roleSelected, setRoleSelected] = useState('');
  const [title, setTitle] = useState(null);
  const [filterUserRoleList, setFilterUserRoleList] = useState([]);
  const [filteredValues, setFilteredValues] = useState({ text: '', role: [] });
  const [FList, LList] = useFetch(BaseConfig.service.userrole.list, Service.UserRole.List());
  const [FDetail, LDetail] = useFetch(BaseConfig.service.userrole.detail, Service.UserRole.Detail());
  const [FDelete, LDelete] = useFetch(BaseConfig.service.userrole.delete, Service.UserRole.Delete());
  const [FSave, LSave] = useFetch(BaseConfig.service.userrole.save, Service.UserRole.Save());
  const [FUserRoleList, LUserRoleList] = useFetch(BaseConfig.service.userrole.selectList, Service.UserRole.List());
  const [FUserRoleListByFilter, LUserRoleListByFilter] = useFetch(BaseConfig.service.userrole.list, Service.UserRole.ListByFilter());
  const [isModalSubmitted, setIsModalSubmitted] = useState(false);

  const getList = () => {
    FList().then(response => {
      if (response && response.error === false) {
        setData(response.data);
        setCommon(response.common);
      }
    });
  };

  const getUserRoleSelectList = () => {
    FUserRoleList().then(response => {
      setFilterUserRoleList(response);
    });
  };

  useEffect(() => {
    getList();
    getUserRoleSelectList();
  }, []);

  const getFilteredUserRoleList = (form, formData, valid) => {
    FUserRoleListByFilter({ fullName: filteredValues.text, role: filteredValues.role.map(m => m.value) }).then(response => {
      if (response && response.error === false) {
        setData(response.data);
        setCommon(response.common);
      }
    });
  };

  const getDetail = id => {
    FDetail({ Id: id }).then(response => {
      if (response && response.error === false) {
        setUserSelected(common.user.filter(f => f.value === response.data.userId)[0]);
        setRoleSelected(common.role.filter(f => f.value === response.data.roleId)[0]);
        setDetail({ ...response.data, userSelected, roleSelected });
        setSwitchStatus(response.data.status);
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
    });
  };

  const onDetail = id => {
    setTitle(false);
    getDetail(id);
    setModal(true);
    setDetail({ ...detail, id });
  };

  const getDelete = id => {
    Loading();
    FDelete({ Id: id }).then(response => {
      if (response && response.error === false) {
        Notify.success(T('İşlem başarıyla gerçekleşti.'));
        getList();
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
      Loading();
    });
  };

  const onDelete = id =>
    Confirm({
      title: T('Emin misiniz?'),
      type: { warning: true },
      description: T('Bu kullanıcı rolü kaldırılacaktır.'),
      onConfirm: () => {
        getDelete(id);
      },
      onCancel: () => { },
    });

  const getSave = formData => {
    Loading();
    FSave(formData).then(response => {
      if (response && response.error === false) {
        getFilteredUserRoleList();
        Notify.success(T('İşlem başarıyla tamamlandı!'));
        setModal(false);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    });
  };

  const onSave = (form, formData, valid) => {
    setIsModalSubmitted(true);

    if (valid && formData && userSelected && roleSelected) {
      formData.status = switchStatus;
      getSave(formData);
    }
  };

  const addNew = () => {
    setTitle(true);
    setDetail({
      id: 0,
      roleId: 0,
      userId: 0,
      status: false,
    });
    setUserSelected('');
    setRoleSelected('');
    setSwitchStatus(true);
    setIsModalSubmitted(false);
    setModal(true);
  };

  const changeUserSelected = opt => {
    setUserSelected(opt, opt && opt.value);
  };

  const changeRoleSelected = opt => {
    setRoleSelected(opt, opt && opt.value);
  };

  const closeModal = () => {
    setModal(false);
    setIsModalSubmitted(false);
  };

  return (
    <>
      <Card
        Title={T('Kullanıcı Rolü')}
        Description=""
        Logo="sdf"
        className="ac-lead-list__card"
      >
        {
          BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-md-12 text-right">
                <button onClick={() => addNew()} type="button" className="btn btn-dark btn-sm">{T('Yeni Ekle')}</button>
              </div>
            </div>
          )
        }
        <ValidForm
          nosubmit
          onSubmit={(form, formData, valid) => {
            getFilteredUserRoleList(form, formData, valid);
          }}
          novalid
        >
          <div className="row">
            <div className="col-md-4">
              <label>{T('Filtre')}</label>
              <input type="text" name="text" id="Text" className="form-control" placeholder={T('Filter')} autoComplete="off" onChange={item => setFilteredValues({ ...filteredValues, text: item.target.value })} />
            </div>
            <div className="col-md-4">
              <label>{T('Rol')}</label>
              <Select
                options={filterUserRoleList}
                isMulti
                onChange={value => {
                  if (value) {
                    setFilteredValues({ ...filteredValues, role: value });
                  } else {
                    setFilteredValues({ ...filteredValues, role: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.role}
              />
            </div>
            <div className="col-md-4 text-right d-flex align-items-end flex-row-reverse">
              <button
                onClick={() => {
                  setFilteredValues({ role: [], text: '' });
                  getList();
                }}
                type="reset"
                className="btn btn-outline-danger btn-sm"
              >
                {T('Filtreleri Temizle')}
              </button>
              <button
                type="submit"
                className="btn btn-outline-success btn-sm mx-2"
              >
                {T('Filtrele')}
              </button>
            </div>
          </div>
        </ValidForm>
        <div className="col-md-12">
          <DataTable
            columns={DataTableColumns.userRole(onDetail, onDelete)}
            data={data}
            pagination
            defaultSortField="id"
            defaultSortAsc={false}
          />
        </div>
      </Card>
      <Modal
        Title={title ? T('Yeni Kullanıcı Rolü') : T('Kullanıcı Rolünü Düzenle')}
        onClose={() => closeModal()}
        Show={modal}
      >

        <ValidForm className="form-horizontal m-t-10" nosubmit onSubmit={(form, formData, valid) => onSave(form, formData, valid)} data={detail}>
          <input
            className="form-control"
            type="hidden"
            name="id"
            id="id"
            placeholder={T('id')}
          />
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Kullanıcı Rolü')}
            </label>
            <div className="col-md-6">
              <Select
                name="roleId"
                inputId="roleId"
                options={common.role}
                className="react-select-valid"
                value={roleSelected}
                onChange={opt => changeRoleSelected(opt)}
                placeholder={isModalSubmitted ? (roleSelected ? T('Seçiniz...') : <span className="text-danger">{T('Zorunlu...')}</span>) : T('Seçiniz...')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Kullanıcı Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="userId"
                inputId="userId"
                options={common.user}
                className="react-select-valid"
                value={userSelected}
                onChange={opt => changeUserSelected(opt)}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
                placeholder={isModalSubmitted ? (userSelected ? T('Seçiniz...') : <span className="text-danger">{T('Zorunlu...')}</span>) : T('Seçiniz...')}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Durum')}
            </label>
            <div className="col-md-6">
              <Switch disabled={!BaseConfig.utilities.isUserManagerManagement()} onChange={() => setSwitchStatus(!switchStatus)} checked={switchStatus} />
            </div>
          </div>
          {BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-sm-12 text-right">
                <button className="btn btn-success w-md" type="submit">
                  {T('Kaydet')}
                </button>
              </div>
            </div>
          )}
        </ValidForm>
      </Modal>
    </>
  );
};
