/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { T } from 'react-translator-component';
import DataTable from 'react-data-table-component';
import Switch from 'react-switch';
import ValidForm from 'react-valid-form-component';
import useFetch from 'react-use-fetch-hooks';
import Select from 'react-select';

import { Loading } from 'react-loading-ui';
import { Card, Confirm, Notify } from '../../components'; // Notify eklenecek.
import { Modal } from '../../components/Modal';
import { DataTableColumns } from '../../config/DataTableColumns';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';
import { PermissionTypes, WorkflowUserRole } from '../../config/Enums';
import { UserRole } from '../UserRole';

export const Permission = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});
  const [common, setCommon] = useState({});
  const [switchStatus, setSwitchStatus] = useState(true);
  const [isWriteSwitch, setIsWriteSwitch] = useState(true);
  const [userSelected, setUserSelected] = useState('');
  const [roleSelected, setRoleSelected] = useState('');
  const [roleList, setRoleList] = useState('');
  const [corporationSelected, setCorporationSelected] = useState({ corporationIdList: [] });
  const [permissionSelected, setPermissionSelected] = useState('');
  const [title, setTitle] = useState(null);
  const [filterUserRoleList, setFilterUserRoleList] = useState([]);
  const [filteredValues, setFilteredValues] = useState({ text: '', role: [], permission: [], corporation: [] });
  const [FList, LList] = useFetch(BaseConfig.service.permission.list, Service.Permission.List());
  const [FDetail, LDetail] = useFetch(BaseConfig.service.permission.detail, Service.Permission.Detail());
  const [FDelete, LDelete] = useFetch(BaseConfig.service.permission.delete, Service.Permission.Delete());
  const [FSave, LSave] = useFetch(BaseConfig.service.permission.save, Service.Permission.Save());
  const [FUserPermissionListByFilter, LUserPermissionListByFilter] = useFetch(BaseConfig.service.permission.list, Service.Permission.ListByFilter());
  const [FUserRoleListByUser, LUserRoleListByUser] = useFetch(BaseConfig.service.userrole.selectListByUser, Service.UserRole.SelectListByUser());
  const [isModalSubmitted, setIsModalSubmitted] = useState(false);

  const getList = () => {
    FList().then(response => {
      if (response && response.error === false) {
        setData(response.data);
        setCommon(response.common);
      }
    });
  };

  const getUserRoleSelectList = userId => {
    FUserRoleListByUser({ Id: userId }).then(response => {
      // Belirli roller için yetki bulunmaktadır
      const roleFilter = response.filter(f => f.label.trim().includes('Workflow') && f.status);
      if (roleFilter.length > 0) {
        setRoleList(roleFilter);
      } else {
        Notify.error(T('Seçitğiniz kullanıcı için yetki verilebilecek rol bulunmamaktadır. Yetki verilebilecek bir rol ekleyiniz!'));
      }
    });
  };

  const getFilteredPermissionList = (form, formData, valid) => {
    FUserPermissionListByFilter({
      fullName: filteredValues.text,
      role: filteredValues.role?.map(m => m.value),
      permission: filteredValues.permission?.map(m => m.value),
      corporation: filteredValues.corporation?.map(m => m.value),
    })
      .then(response => {
        if (response && response.error === false) {
          setData(response.data);
          // setCommon(response.common);
        }
      });
  };

  useLayoutEffect(() => {
    getList();
  }, []);

  const isCorporationDisabled = value => {
    if (value === PermissionTypes.Settings || value === PermissionTypes.Chart) return true;
    return false;
  };

  const getDetail = id => {
    FDetail({ Id: id }).then(response => {
      if (response && response.error === false) {
        setUserSelected(common.user.filter(f => f.value === response.data.userId)[0]);
        setRoleSelected(common.roleList.filter(f => f.value === response.data.roleId)[0]);
        setCorporationSelected({ corporationIdList: common.corporationList.filter(f => f.value === response.data.corporationId) });
        setPermissionSelected(common.permissionList.filter(f => f.value === response.data.permissionId)[0]);
        setDetail({ ...response.data, userSelected, roleSelected, corporationSelected, permissionSelected });
        setSwitchStatus(response.data.status);
        setIsWriteSwitch(response.data.isWrite);
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
    });
  };

  const onDetail = id => {
    setTitle(false);
    getDetail(id);
    setModal(true);
    setDetail({ ...detail, id });
  };

  const getDelete = id => {
    Loading();
    FDelete({ Id: id }).then(response => {
      if (response && response.error === false) {
        Notify.success(T('İşlem başarıyla gerçekleşti.'));
        getFilteredPermissionList();
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
      Loading();
    });
  };

  const onDelete = id =>
    Confirm({
      title: T('Emin misiniz?'),
      type: { warning: true },
      description: T('Bu kullanıcının yetkisi kaldırılacaktır.'),
      onConfirm: () => {
        getDelete(id);
      },
      onCancel: () => { },
    });

  const getSave = formData => {
    Loading();
    formData.corporationIdList = corporationSelected.corporationIdList?.map(m => m.value);
    FSave(formData).then(response => {
      if (response && response.error === false) {
        getFilteredPermissionList();
        Notify.success(T('İşlem başarıyla tamamlandı!'));
        setModal(false);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    });
  };

  const onSave = (form, formData, valid) => {
    setIsModalSubmitted(true);

    if (valid && formData && userSelected && roleSelected) {
      formData.status = switchStatus;
      formData.isWrite = isWriteSwitch;
      getSave(formData);
    }
  };

  const addNew = () => {
    setTitle(true);
    setDetail({
      id: 0,
      roleId: 0,
      userId: 0,
      status: false,
    });
    setUserSelected('');
    setRoleSelected('');
    setPermissionSelected('');
    setCorporationSelected({ corporationIdList: [] });
    setSwitchStatus(true);
    setIsModalSubmitted(false);
    setModal(true);
  };

  const changeUserSelected = opt => {
    setUserSelected(opt, opt && opt.value);
  };

  const closeModal = () => {
    setModal(false);
    setIsModalSubmitted(false);
  };

  useEffect(() => {
    if (permissionSelected.value === PermissionTypes.Settings) setCorporationSelected({ corporationIdList: [] });
  }, [permissionSelected]);

  return (
    <>
      <Modal
        Title={title ? T('Yeni Kullanıcı Yetkisi') : T('Kullanıcı Yetkisini Düzenle')}
        onClose={() => closeModal()}
        Show={modal}
        isScrollable
      >

        <div className="alert alert-danger" role="alert">
          <table className="table-sm">
            <tbody>
              <tr>
                <th>Yetkiler</th>
                <th>Verilebileceği Roller</th>
              </tr>
              <tr>
                <td>Settings</td>
                <td>Manager</td>
              </tr>
              <tr>
                <td>Agreements</td>
                <td>Manager, Finance</td>
              </tr>
              <tr>
                <td>AllForms(pasif)</td>
                <td />
              </tr>
              <tr>
                <td>Payments</td>
                <td>Manager, Finance</td>
              </tr>
              <tr>
                <td>All Expense</td>
                <td>Manager, Finance, HR, Purchasing, User</td>
              </tr>
              <tr>
                <td>All Annual Leave</td>
                <td>Manager, Finance, HR, Purchasing, User</td>
              </tr>
              <tr>
                <td>All Purchasing</td>
                <td>Manager, Finance, HR, Purchasing, User</td>
              </tr>
            </tbody>
          </table>
        </div>

        <ValidForm className="form-horizontal m-t-10" nosubmit onSubmit={(form, formData, valid) => onSave(form, formData, valid)} data={detail}>
          <input
            className="form-control"
            type="hidden"
            name="id"
            id="id"
            placeholder={T('id')}
          />
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Kullanıcı Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="userId"
                inputId="userId"
                options={common.user}
                className="react-select-valid"
                value={userSelected}
                onChange={opt => { getUserRoleSelectList(opt.value); changeUserSelected(opt); }}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
                placeholder={isModalSubmitted ? (userSelected ? T('Seçiniz...') : <span className="text-danger">{T('Zorunlu...')}</span>) : T('Seçiniz...')}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Rol Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="roleId"
                inputId="roleId"
                options={roleList}
                className="react-select-valid"
                value={roleSelected}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
                onChange={opt => setRoleSelected(opt)}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Yetki Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="permissionId"
                inputId="permissionId"
                options={common.permissionList}
                className="react-select-valid"
                value={permissionSelected}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
                onChange={opt => setPermissionSelected(opt)}
              />
            </div>
          </div>
          { permissionSelected.value === PermissionTypes.Agreements ? (
            <div className="col-md-12 row mb-3">
              <label className="control-label col-md-2 mt-2 ml-3">
                {T('Ekleme, Güncelleme')}
              </label>
              <div className="col-md-6">
                <Switch disabled={!BaseConfig.utilities.isUserManagerManagement()} onChange={() => setIsWriteSwitch(!isWriteSwitch)} checked={isWriteSwitch} />
              </div>
            </div>
          ) : ('')
          }

          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Şirket Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="corporationIdList"
                isMulti={!detail.id}
                inputId={!isCorporationDisabled(permissionSelected.value) && corporationSelected.corporationIdList?.length === 0 ? 'corporationIdList' : 'no-validation'}
                className={!isCorporationDisabled(permissionSelected.value) && corporationSelected.corporationIdList?.length === 0 ? 'react-select-valid' : ''}
                onInvalid={value => !isCorporationDisabled(permissionSelected.value) && value.length === 0}
                options={common.corporationList}
                onChange={value => {
                  if (value?.length !== 0) {
                    setCorporationSelected({ ...corporationSelected, corporationIdList: detail.id ? [value] : value });
                  } else {
                    setCorporationSelected({ ...corporationSelected, corporationIdList: [] });
                  }
                }}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement() || isCorporationDisabled(permissionSelected.value)}
                value={corporationSelected.corporationIdList}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Durum')}
            </label>
            <div className="col-md-6">
              <Switch disabled={!BaseConfig.utilities.isUserManagerManagement()} onChange={() => setSwitchStatus(!switchStatus)} checked={switchStatus} />
            </div>
          </div>
          {BaseConfig.utilities.isUserManagerManagement() && (
          <div className="row">
            <div className="col-sm-12 text-right">
              <button className="btn btn-success w-md" type="submit">
                {T('Kaydet')}
              </button>
            </div>
          </div>
          )}
        </ValidForm>
      </Modal>
      <Card
        Title={T('Kullanıcı İzinleri')}
        Description=""
        Logo="sdf"
        className="ac-lead-list__card"
      >
        {
          BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-md-12 text-right">
                <button onClick={() => addNew()} type="button" className="btn btn-dark btn-sm">{T('Yeni Ekle')}</button>
              </div>
            </div>
          )
        }
        <ValidForm
          nosubmit
          onSubmit={(form, formData, valid) => {
            getFilteredPermissionList(form, formData, valid);
          }}
          novalid
        >
          <div className="row">
            <div className="col-md-3">
              <label>{T('Full Name')}</label>
              <input type="text" name="text" id="Text" className="form-control" placeholder={T('Full Name')} autoComplete="off" onChange={item => setFilteredValues({ ...filteredValues, text: item.target.value })} />
            </div>
            <div className="col-md-3">
              <label>{T('Rol')}</label>
              <Select
                options={common.roleList}
                isMulti
                onChange={value => {
                  if (value) {
                    setFilteredValues({ ...filteredValues, role: value });
                  } else {
                    setFilteredValues({ ...filteredValues, role: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.role}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Yetki')}</label>
              <Select
                options={common.permissionList}
                isMulti
                onChange={value => {
                  if (value) {
                    setFilteredValues({ ...filteredValues, permission: value });
                  } else {
                    setFilteredValues({ ...filteredValues, permission: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.permission}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Corporation')}</label>
              <Select
                options={common.corporationList}
                isMulti
                onChange={value => {
                  if (value) {
                    setFilteredValues({ ...filteredValues, corporation: value });
                  } else {
                    setFilteredValues({ ...filteredValues, corporation: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.corporation}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <button
                onClick={() => {
                  setFilteredValues({ text: '', role: [], permission: [], corporation: [] });
                  getList();
                }}
                type="reset"
                className="btn btn-outline-danger btn-sm"
              >
                {T('Filtreleri Temizle')}
              </button>
              <button
                type="submit"
                className="btn btn-outline-success btn-sm mx-2"
              >
                {T('Filtrele')}
              </button>
            </div>
          </div>
        </ValidForm>
        <div className="col-md-12">
          <DataTable
            columns={DataTableColumns.permission(onDetail, onDelete)}
            data={data}
            pagination
            defaultSortField="id"
            defaultSortAsc={false}
          />
        </div>
      </Card>

    </>
  );
};
