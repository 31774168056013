import React from 'react';
import { T } from 'react-translator-component';
import { memoize } from 'react-data-table-component';
import moment from 'moment';
import { BaseConfig } from './AppConfig';

export const DataTableColumns = {
  application: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Ad'),
        selector: 'name',
        sortable: true,
      },
      {
        name: T('Açıklama'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status === true ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Faaliyet'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            { BaseConfig.utilities.isUserManagerManagement() && (
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                title={T('Sil')}
                onClick={() => onDelete(d.id)}
              >
                <i className="fas fa-trash-alt" />
              </button>
            )}
          </span>
        ),
      },
    ],
  ),
  corporation: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Ad'),
        selector: 'name',
        sortable: true,
      },
      {
        name: T('Açıklama'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            { BaseConfig.utilities.isUserManagerManagement() && (
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                title={T('Sil')}
                onClick={() => onDelete(d.id)}
              >
                <i className="fas fa-trash-alt" />
              </button>
            )}
          </span>
        ),
      },
    ],
  ),
  emailTemplate: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Type'),
        selector: 'type',
        sortable: true,
      },
      {
        name: T('Subject'),
        selector: 'subject',
        sortable: true,
      },
      {
        name: T('Html'),
        selector: 'html',
        sortable: true,
      },
      {
        name: T('Comment'),
        selector: 'comment',
        sortable: true,
      },
      {
        name: T('Language'),
        selector: 'language',
        sortable: true,
      },
      {
        name: T('Action'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Edit')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            {
              BaseConfig.utilities.isUserManagerManagement() && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm waves-effect waves-light"
                  title={T('Delete')}
                  onClick={() => onDelete(d.id)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              )
            }
          </span>
        ),
      },
    ],
  ),
  role: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Ad'),
        selector: 'name',
        sortable: true,
      },
      {
        name: T('Açıklama'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Uygulama Adı'),
        selector: 'applicationName',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            {
              BaseConfig.utilities.isUserManagerManagement() && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm waves-effect waves-light"
                  title={T('Sil')}
                  onClick={() => onDelete(d.id)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              )
            }
          </span>
        ),
      },
    ],
  ),
  title: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Ad'),
        selector: 'name',
        sortable: true,
      },
      {
        name: T('Açıklama'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            { BaseConfig.utilities.isUserManagerManagement() && (
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                title={T('Sil')}
                onClick={() => onDelete(d.id)}
              >
                <i className="fas fa-trash-alt" />
              </button>
            )}
          </span>
        ),
      },
    ],
  ),
  department: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Ad'),
        selector: 'name',
        sortable: true,
      },
      {
        name: T('Açıklama'),
        selector: 'description',
        sortable: true,
      },
      {
        name: T('Şirket Adı'),
        selector: 'corporationName',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            {
              BaseConfig.utilities.isUserManagerManagement() && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm waves-effect waves-light"
                  title={T('Sil')}
                  onClick={() => onDelete(d.id)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              )
            }
          </span>
        ),
      },
    ],
  ),
  user: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
        maxWidth: '100px',
        minWidth: '100px',


      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <>
            <span>
              <button
                type="button"
                className="btn btn-dark btn-sm waves-effect waves-light mr-2"
                title={T('Düzenle')}
                onClick={() => onDetail(d.id)}
              >
                <i className="fas fa-address-card" />
              </button>
            </span>
            { BaseConfig.utilities.isUserManagerManagement() && (
              <span>
                <button
                  type="button"
                  className="btn btn-danger btn-sm waves-effect waves-light"
                  title={T('Sil')}
                  onClick={() => onDelete(d.id)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </span>
            )}
          </>
        ),
      },
      {
        name: T('İsim'),
        selector: 'firstName',
        sortable: true,
        maxWidth: '150px',
        minWidth: '150px',
      },
      {
        name: T('Soyisim'),
        selector: 'lastName',
        sortable: true,
        maxWidth: '150px',
        minWidth: '150px',
      },
      {
        name: T('E-posta'),
        selector: 'email',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
        // cell: d => d.facto700TotalRisk.format('c'),
      },
      {
        name: T('Master User'),
        selector: 'masterUserName',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
      },
      {
        name: T('Annual Master User'),
        selector: 'annualMasterUserName',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
      },
      {
        name: T('Departman'),
        selector: 'departmentName',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
      },
      {
        name: T('Corporation'),
        selector: 'corporationName',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
      },
      {
        name: T('Unvan'),
        selector: 'titleName',
        sortable: true,
        maxWidth: '200px',
        minWidth: '200px',
      },
      {
        name: T('Doğum Tarihi'),
        selector: 'birthDate',
        sortable: true,
        maxWidth: '100px',
        minWidth: '100px',
        cell: d => d.birthDate?.toString().split(' ')[0],
      },
      {
        name: T('İşe Alım Tarihi'),
        selector: 'dateOfRecruitment',
        sortable: true,
        filterable: true,
        maxWidth: '100px',
        minWidth: '100px',
        cell: d => d.dateOfRecruitment?.toString().split(' ')[0],
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
    ],
  ),
  userRole: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Rol Adı'),
        selector: 'roleName',
        sortable: true,
      },
      {
        name: T('Kullanıcı Adı'),
        selector: 'userName',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            { BaseConfig.utilities.isUserManagerManagement() && (
            <button
              type="button"
              className="btn btn-danger btn-sm waves-effect waves-light"
              title={T('Sil')}
              onClick={() => onDelete(d.id)}
            >
              <i className="fas fa-trash-alt" />
            </button>
            )}
          </span>
        ),
      },
    ],
  ),
  permission: memoize(
    (onDetail, onDelete) => [
      {
        name: T('Id'),
        selector: 'id',
        sortable: true,
        filterable: true,
      },
      {
        name: T('Yetki Adı'),
        selector: 'permissionName',
        sortable: true,
      },
      {
        name: T('Rol Adı'),
        selector: 'roleName',
        sortable: true,
      },
      {
        name: T('Kullanıcı Adı'),
        selector: 'userName',
        sortable: true,
      },
      {
        name: T('Şirket'),
        selector: 'corporationName',
        sortable: true,
      },
      {
        name: T('Durum'),
        selector: 'status',
        sortable: true,
        cell: d => (d.status ? <span className="badge pb-1 font-12 badge-success">{T('Aktif')}</span> : <span className="badge pb-1 font-12 badge-danger">{T('Pasif')}</span>),
      },
      {
        name: T('Aksiyon'),
        selector: 'id',
        cell: d => (
          <span className="text-nowrap">
            <button
              type="button"
              className="btn btn-dark btn-sm waves-effect waves-light mr-2"
              title={T('Düzenle')}
              onClick={() => onDetail(d.id)}
            >
              <i className="fas fa-address-card" />
            </button>
            { BaseConfig.utilities.isUserManagerManagement() && (
            <button
              type="button"
              className="btn btn-danger btn-sm waves-effect waves-light"
              title={T('Sil')}
              onClick={() => onDelete(d.id)}
            >
              <i className="fas fa-trash-alt" />
            </button>
            )}
          </span>
        ),
      },
    ],
  ),
};
