/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { T } from 'react-translator-component';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Switch from 'react-switch';
import ValidForm from 'react-valid-form-component';
import useFetch from 'react-use-fetch-hooks';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Card, Confirm, Notify } from '../../components'; // Notify eklenecek.
import { Modal } from '../../components/Modal';
import { DataTableColumns } from '../../config/DataTableColumns';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';
import 'react-datepicker/dist/react-datepicker.css';
import { Loading } from 'react-loading-ui';
import { WorkFlowService } from '../../services/WorkflowService';
import { fromDDMMYYYYtoYYYYMMDD, toYYmmDD } from '../../config/Utilities';
import moment from 'moment';


export const User = () => {
  const [modal, setModal] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [detail, setDetail] = useState({});
  const [common, setCommon] = useState({}); // şirket departman vb selectler için
  const [selectValue, setSelectValue] = useState({});
  const [switchStatus, setSwitchStatus] = useState(true);

  const [modalTitle, setModalTitle] = useState(null);
  const [profilPhoto, setProfilPhoto] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const [filteredValues, setFilteredValues] = useState({ text: '', corporation: [], department: [], title: [], annualMaster: '', masterUser: '', birthDate: null, dateOfRecruitment: null });
  const [filteredSwitchStatus, setFilteredSwitchStatus] = useState(true);

  const [FDetail] = useFetch(
    BaseConfig.service.user.detail,
    Service.User.Detail(),
  );
  const [FDelete] = useFetch(
    BaseConfig.service.user.delete,
    Service.User.Delete(),
  );
  const [FSave] = useFetch(
    BaseConfig.service.user.save,
    Service.User.Save(),
  );
  const [FCommon] = useFetch(
    BaseConfig.service.user.common,
    Service.User.Common(),
  );
  const [FDepartmentListByDeparmentId] = useFetch(
    BaseConfig.service.department.selectListByCorporationId,
    Service.Department.SelectListByCorporationId(),
  );
  const [FUserByFilter] = useFetch(
    BaseConfig.service.user.listByFilter,
    Service.User.ListByFilter(),
  );
  const [FDepartmentListbyCorporation] = useFetch(
    BaseConfig.service.department.getDepartmentListByCorporation,
    Service.Department.GetDepartmentListByCorporation(),
  );

  const departmentListbyCorporation = corporationId => {
    const corporation = {};
    corporation.id = corporationId;
    FDepartmentListbyCorporation(corporation).then(response => {
      if (!response || response.error) { return; }
      setCommon({ ...common, departmentList: response.data });
    });
  };

  const getDepartmentListByCorporationId = corporations => {
    FDepartmentListByDeparmentId(corporations).then(response => setCommon({ ...common, departmentList: response }));
  };

  const getUserByFilter = (form, formData, valid) => {
    FUserByFilter({
      text: filteredValues.text,
      corporation: filteredValues.corporation?.map(m => m.value),
      department: filteredValues.department?.map(m => m.value),
      title: filteredValues.title?.map(m => m.value),
      annualMaster: filteredValues?.annualMaster,
      masterUser: filteredValues.masterUser,
      birthDate: new Date(filteredValues.birthDate?.normalizeHours()),
      dateOfRecruitment: new Date(filteredValues.dateOfRecruitment?.normalizeHours()),
      status: filteredSwitchStatus,
    }).then(response => {
      if (response && response.error === false) {
        setDataTable(response.data);
      }
    });
  };

  useEffect(() => {
    FCommon().then(response => {
      if (response && response.error === false) {
        setCommon(response.data);
      }
    });
    FUserByFilter({status:null}).then(response => {
      if (response && response.error === false) {
        setDataTable(response.data);
      }
    });
  }, []);

  const getDetail = id => {
    Loading();
    FDetail({ Id: id }).then(response => {
      if (response && response.error === false) {
        const departmentSelected = common.departmentList.filter(f => f.value === response.data.departmentId)[0];
        const corporationSelected = common.corporationList.filter(f => f.value === response.data.corporationId)[0];
        const titleSelected = common.titleList.filter(f => f.value === response.data.titleId);
        const masterUserSelected = common.userList.filter(f => f.value === response.data.masterUserId);
        const annualMasterSelected = common.userList.filter(f => f.value === response.data.annualMasterId);
        setDetail(response.data);
        setSelectValue({ firstName: response.data.firstName, lastName: response.data.lastName, email:response.data.email, departmentSelected, corporationSelected, titleSelected, masterUserSelected, annualMasterSelected, dateBirth: response.data.birthDate, dateOfRecruitment: response.data.dateOfRecruitment });
        setSwitchStatus(response.data.status);
        setImagePreviewUrl('');
        departmentListbyCorporation(response.data.corporationId);
      }
      Loading();
      Notify.error(T(response.message));
    });
  };

  const onDetail = id => {
    setModalTitle(false);
    getDetail(id);
    setModal(true);
  };

  const getDelete = id => {
    FDelete({ Id: id }).then(response => {
      if (response && response.error === false) {
        Notify.success(T('İşlem başarıyla gerçekleşti.'));
        getUserByFilter();
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
    });
  };

  const onDelete = id =>
    Confirm({
      title: T('Emin misiniz?'),
      type: { warning: true },
      description: T('Bu kullanıcı kaldırılacaktır.'),
      onConfirm: () => {
        getDelete(id);
      },
      onCancel: () => { },
    });

  const createLeaveForNewUser = userId => {
    const leaveData = {
      UserId: userId,
    };
    WorkFlowService.CreateAnnualLeaveByUserId(leaveData).then(response => {
      if (response && !response.error) {
        Notify.success(T('Annual Leave Datas successfully created for user.'));
      } else {
        Notify.error(T('Annual Leave Datas could not be created for new user.'));
      }
    });
  };

  const getSave = formData => {
    Loading();
    formData.birthDate = formData.birthDate ? new Date(fromDDMMYYYYtoYYYYMMDD(formData.birthDate)) : null;
    formData.dateOfRecruitment = formData.dateOfRecruitment ? new Date(fromDDMMYYYYtoYYYYMMDD(formData.dateOfRecruitment)) : null;

    const copyData = { ...formData, profilPhoto };

    if (copyData.birthDate) {
      const birthDate = new Date(copyData.birthDate);
      copyData.birthDate = new Date(birthDate.getTime() - (birthDate.getTimezoneOffset() * 60000)).toJSON();
    }

    if (copyData.dateOfRecruitment) {
      const dateOfRecruitment = new Date(copyData.dateOfRecruitment);
      copyData.dateOfRecruitment = new Date(dateOfRecruitment.getTime() - (dateOfRecruitment.getTimezoneOffset() * 60000)).toJSON();
    }

    const postData = new FormData();
    Object.keys(copyData).forEach(item => {
      if (copyData[item]) {
        postData.append(item, copyData[item]);
      }
    });

    const xhr = new XMLHttpRequest();
    xhr.open('POST', BaseConfig.service.user.save, true);
    xhr.setRequestHeader('Authorization', `Bearer ${BaseConfig.utilities.userToken()}`);
    xhr.send(postData);
    xhr.onload = () => {
      const response = JSON.parse(xhr.response);

      if (xhr.status === 200 && !response.error) {
        Notify.success(T('İşlem başarıyla tamamlandı!'));
        // This endpoint will check if the user has annual leave value data for current year and if there is not it will be created.
        if (formData && formData.id <= 0) createLeaveForNewUser(response.data);
        getUserByFilter();
        setModal(false);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    };
  };

  const onSave = (form, formData, valid) => {
    if (valid && formData) {
      formData.email = selectValue.email;
      formData.firstName = selectValue.firstName;
      formData.lastName = selectValue.lastName;
      formData.MasterUserId = selectValue.masterUserSelected.value;
      formData.AnnualMasterId = selectValue.annualMasterSelected.value;
      formData.status = switchStatus;
      formData.birthDate = selectValue.dateBirth;
      formData.dateOfRecruitment = selectValue.dateOfRecruitment;

      getSave(formData);
    }
  };

  const addNew = () => {
    setModalTitle(true);
    setDetail({
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      masterUserId: 0,
      annualMasterId: 0,
      corporationId: null,
      departmentId: null,
      titleId: null,
      birthDate: '',
      dateOfRecruitment: '',
      lastRequest: '',
      lastLoginDate: '',
      status: true,
      userId: 0,
    });
    setSelectValue({});
    setSwitchStatus(true);
    setModal(true);
    setImagePreviewUrl('');
  };

  const onFileChangeHandler = event => {
    setProfilPhoto(event.target.files[0]);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    if (filteredValues.corporation?.length !== 1) {
      setFilteredValues({ ...filteredValues, department: [] });
    }
  }, [filteredValues.corporation]);

  const rexexpPhone = '^0[2-5]{1}[0-9]{2}[0-9]{3}[0-9]{2}[0-9]{2}$';
  return (
    <>
      <Card
        Title="Kullanıcı"
        Description=""
        className="ac-lead-list__card mt-5"
      >
        <div className="row">
          <div className="col-md-12 text-right">
            {
              BaseConfig.utilities.isUserManagerManagement() && (
                <button
                  onClick={() => addNew()}
                  type="button"
                  className="btn btn-dark btn-sm"
                >
                  {T('Yeni Ekle')}
                </button>
              )
            }

          </div>
        </div>
        <ValidForm
          nosubmit
          onSubmit={(formData, form, valid) => {
            getUserByFilter(formData, form, valid);
          }}
          novalid
        >
          <div className="row mb-2">
            <div className="col-md-3">
              <label>{T('Name or Email')}</label>
              <input type="text" name="text" id="Text" className="form-control" placeholder={T('Name or Email')} autoComplete="off" onChange={item => setFilteredValues({ ...filteredValues, text: item.target.value })} />
            </div>
            <div className="col-md-3">
              <label>{T('Şirket')}</label>
              <Select
                options={common.corporationList}
                isMulti
                onChange={value => {
                  if (value?.length === 1) {
                    getDepartmentListByCorporationId({ corporations: value.map(m => m.value) });
                  }
                  setFilteredValues({ ...filteredValues, corporation: value });
                }}
                inputId="no-validation"
                value={filteredValues.corporation}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Departman')}</label>
              <Select
                options={common.departmentList}
                isMulti
                onChange={value => {
                  if (value?.length !== 0) {
                    setFilteredValues({ ...filteredValues, department: value });
                  } else {
                    setFilteredValues({ ...filteredValues, department: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.corporation?.length === 1 ? filteredValues.department : null}
                isDisabled={common.departmentList?.length === 0}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Unvan')}</label>
              <Select
                options={common.titleList}
                isMulti
                onChange={value => {
                  if (value) {
                    setFilteredValues({ ...filteredValues, title: value });
                  } else {
                    setFilteredValues({ ...filteredValues, title: [] });
                  }
                }}
                inputId="no-validation"
                value={filteredValues.title}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Annual Master User')}</label>
              <input type="text" name="annualMaster" id="annualMaster" className="form-control" placeholder={T('Annual Master User')} autoComplete="off" onChange={item => setFilteredValues({ ...filteredValues, annualMaster: item.target.value })} />
            </div>
            <div className="col-md-3">
              <label>{T('Master User')}</label>
              <input type="text" name="masterUser" id="masterUser" className="form-control" placeholder={T('Master User')} autoComplete="off" onChange={item => setFilteredValues({ ...filteredValues, masterUser: item.target.value })} />
            </div>
            <div className="col-md-3">
              <label>{T('Birth Date')}</label>
              <DatePicker
                selected={filteredValues.birthDate}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                name="birthDate"
                id="birthDate"
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                onChange={date => setFilteredValues({ ...filteredValues, birthDate: date })}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Date of Recruitment')}</label>
              <DatePicker
                selected={filteredValues.dateOfRecruitment}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                name="dateOfRecruitment"
                id="dateOfRecruitment"
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                onChange={date => setFilteredValues({ ...filteredValues, dateOfRecruitment: date })}
              />
            </div>
            <div className="col-md-3">
              <label>{T('Durum')}</label>
              <div className="col-md-3">
                <Switch
                  onChange={()=>setFilteredSwitchStatus(!filteredSwitchStatus)}
                  checked={filteredSwitchStatus}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-right">
              <button
                type="submit"
                className="btn btn-outline-success btn-sm mx-2"
              >
                {T('Filtrele')}
              </button>
              <button
                type="reset"
                className="btn btn-outline-danger btn-sm"
                onClick={() => {
                  setFilteredValues({ text: '', corporation: [], department: [], title: [], annualMaster: '', masterUser: '', birthDate: null, dateOfRecruitment: null });
                  FUserByFilter({}).then(response => {
                    if (response && response.error === false) {
                      setDataTable(response.data);
                    }
                  });
                }}
              >
                {T('Filtreleri Temizle')}
              </button>
            </div>
          </div>
        </ValidForm>

        <div className="col-md-12">
          <DataTableExtensions
            columns={DataTableColumns.user(onDetail, onDelete)}
            data={dataTable}
            export={false}
            print={false}
            filter={false}
          >
            <DataTable
              pagination
              defaultSortField="id"
              defaultSortAsc={false}
              noHeader
            />
          </DataTableExtensions>
        </div>
      </Card>

      <Modal
        Title={modalTitle ? 'Yeni Kullanıcı' : 'Kullanıcıyı Düzenle'}
        onClose={() => setModal(false)}
        Show={modal}
        isScrollable
      >
        <ValidForm
          className="form-horizontal m-t-10"
          nosubmit
          onSubmit={(form, formData, valid) => onSave(form, formData, valid)}
          data={detail}
        >
          <input
            className="form-control"
            type="hidden"
            name="id"
            id="id"
            placeholder={T('id')}
          />
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Profil Fotoğrafı')}
            </label>
            <div className="col-md-6 d-flex align-items-center">
              <div className="user-image">
                {detail.profilPhotoPath || imagePreviewUrl ?
                  (
                    <img src={`${imagePreviewUrl || (BaseConfig.service.user.root + '/' + detail.profilPhotoPath)}`} alt="user" id="image-rounded-user" className="rounded-circle img-rounded img-fluid" widt="64" height="64" />
                  ) : (<img src="/assets/images/users/user-none.png" alt="user" id="image-rounded-user" className="rounded-circle img-rounded img-fluid" widt="64" height="64" />)}
              </div>
              <input
                type="file"
                name="profilPhoto"
                className="form-control-file"
                accept="image/*"
                onChange={onFileChangeHandler}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('İsim')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="firstName"
                id="firstName"
                // required
                value={selectValue.firstName}
                onChange={item => setSelectValue({ ...selectValue, firstName: item.target.value })}
                placeholder={T('İsim')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
              />
            </div>
          </div> 
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Soyisim')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="lastName"
                id="lastName"
                value={selectValue.lastName}
                onChange={item => setSelectValue({ ...selectValue, lastName: item.target.value  })}
                // required
                placeholder={T('Soyisim')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('E-posta')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                inputId="no-validation"
                type="email"
                name="email"
                id="email"
                value={selectValue.email}
                onChange={item => {setSelectValue({ ...selectValue, email: item.target.value  }); setDetail({...detail, email: item.target.value})}}
                // required)
                placeholder={T('E-posta')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
                maxLength="50"
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Master User')}
            </label>
            <div className="col-md-6">
              <Select
                name="masterUserId"
                inputId="masterUserId no-validation"
                options={common.userList}
                className="react-select-valid"
                value={selectValue.masterUserSelected}
                onChange={opt => setSelectValue({ ...selectValue, masterUserSelected: opt })}
                placeholder={T('Seçiniz')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Annual Leave Master User')}
            </label>
            <div className="col-md-6">
              <Select
                name="annualMasterId"
                inputId="annualMasterId no-validation"
                options={common.userList}
                className="react-select-valid"
                value={selectValue.annualMasterSelected}
                onChange={opt => setSelectValue({ ...selectValue, annualMasterSelected: opt })}
                placeholder={T('Seçiniz')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Şirket Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="corporationId"
                inputId="corporationId"
                options={common.corporationList}
                className="react-select-valid"
                value={selectValue.corporationSelected}
                onChange={opt => {
                  departmentListbyCorporation(opt.value);
                  setSelectValue({ ...selectValue, corporationSelected: opt, corporationId: opt && opt.value, departmentSelected: 0, departmentId: null });
                  setDetail({ ...detail, departmentId: null, corporationId: opt && opt.value });
                }}
                placeholder={T('Seçiniz')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Departman Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="departmentId"
                inputId="departmentId"
                options={common.departmentList}
                className="react-select-valid"
                value={selectValue.departmentSelected}
                onChange={opt => setSelectValue({ ...selectValue, departmentSelected: opt, departmentId: opt && opt.value })}
                placeholder={T('Seçiniz')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Unvan Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="titleId"
                inputId="titleId"
                options={common.titleList}
                className="react-select-valid"
                value={selectValue.titleSelected}
                onChange={opt => setSelectValue({ ...selectValue, titleSelected: opt, titleId: opt && opt.value })}
                placeholder={T('Seçiniz')}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Doğum Tarihi')}
            </label>
            <div className="col-md-6">
              <DatePicker
                selected={
                  selectValue.dateBirth
                    ? new Date(fromDDMMYYYYtoYYYYMMDD(selectValue.dateBirth))
                    : new Date()
                }
                onChange={date => (date === null ? new Date() : setSelectValue({ ...selectValue, dateBirth: toYYmmDD(date) }))}
                dateFormat="dd.MM.yyyy"
                className="form-control"
                name="birthDate"
                id="birthDate"
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('İşe Alım Tarihi')}
            </label>
            <div className="col-md-6">
              <DatePicker
                selected={
                  selectValue.dateOfRecruitment
                    ? new Date(fromDDMMYYYYtoYYYYMMDD(selectValue.dateOfRecruitment))
                    : new Date()
                }
                onChange={date => (date === null ? new Date() : setSelectValue({ ...selectValue, dateOfRecruitment: toYYmmDD(date) }))}
                dateFormat="dd.MM.yyyy"
                className="form-control"
                name="dateOfRecruitment"
                id="dateOfRecruitment"
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Durum')}
            </label>
            <div className="col-md-6">
              <Switch
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                onChange={() => setSwitchStatus(!switchStatus)}
                checked={switchStatus}
              />
            </div>
          </div>

          {BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-sm-12 text-right">
                <button className="btn btn-success w-md" type="submit">
                  {T('Kaydet')}
                </button>
              </div>
            </div>
          )}
        </ValidForm>
      </Modal>
    </>
  );
};
