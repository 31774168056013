import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';

export const Collapse = props => {
  const [open, setOpen] = useState(false);

  const { children, padClass, title, rebate, pullRight, colSpan, group, titlePad } = props;

  return (
    <>
      <tr onClick={() => setOpen(!open)}>
        <td className={padClass}>
          <span className={titlePad}>
            <button type="button" className="btn btn-sm btn-light waves-effect waves-light mr-2"><i className={`fas ${open ? 'fa-minus' : 'fa-plus'}`} /></button>
            {/* {logo && <img src={`/assets/company/${logo}`} alt={title} className="company-logo" />} */}
            <span>{title}</span>
          </span>
        </td>
        <td colSpan={colSpan}>
          <span className="mr-1">$</span>
          <span>{parseFloat(rebate).toFixed(0).format('c')}</span>
          {pullRight && <span className="pull-right">{pullRight}</span>}
        </td>
        {
          colSpan === '0' &&
          (
            <td>
              <span>{group}</span>
            </td>
          )
        }
      </tr>
      {open === true && children}
    </>
  );
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  padClass: PropTypes.string,
  title: PropTypes.any.isRequired,
  rebate: PropTypes.number.isRequired,
  pullRight: PropTypes.string,
  colSpan: PropTypes.string,
  group: PropTypes.string,
  titlePad: PropTypes.string,
};

Collapse.defaultProps = {
  padClass: '',
  pullRight: null,
  colSpan: '2',
  group: '',
  titlePad: '',
};

export const UserHeader = () => (
  <tr className="report-header">
    <td className="pl-5">
      <b className="pl-3">{T('Login')}</b>
    </td>
    <td>
      <b>{T('Rebate')}</b>
    </td>
    <td>
      <b>{T('Group')}</b>
    </td>
  </tr>
);

export const SymbolHeader = props => {
  const { login } = props;
  return (
    <tr className="report-header">
      <td className="pl-5">
        <b className="pl-5">
          <span>Symbols for </span>
          {login}
        </b>
      </td>
      <td colSpan="2">
        <b>Rebate</b>
      </td>
    </tr>
  );
};

SymbolHeader.propTypes = {
  login: PropTypes.number.isRequired,
};

export const Symbol = props => {
  const { symbol, rebate } = props;
  return (
    <tr className="report-symbol">
      <td className="pl-5">
        <span className="pl-5">{symbol}</span>
      </td>
      <td colSpan="2">
        <span className="mr-1">$</span>
        <span>{parseFloat(rebate).toFixed(0).format('c')}</span>
      </td>
    </tr>
  );
};

Symbol.propTypes = {
  symbol: PropTypes.string.isRequired,
  rebate: PropTypes.number.isRequired,
};

export const NoData = () => (
  <tr>
    <td colSpan="3">{T('No data')}</td>
  </tr>
);
