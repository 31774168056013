import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from '../views/Layout';
import { Service } from '../services';
import { BaseConfig } from '../config/AppConfig';
import { Menu } from '../config/Menu';

const { main } = Menu;
const menuItem = Menu.menuItems;

const Context = React.createContext();

const AuthConsumer = Context.Consumer;


class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: 0,
      menu: null,
      menuItems: [],
    };

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    const { authorized } = this.state;

    if (authorized === 0) {
      Service.User.Authenticated().then(response => {
        if (response !== false) {
          const user = BaseConfig.utilities.user();
          if (user) {
            this.setState({
              authorized: 1,
              menu: {
                main: main.filter(f => f.roles.length === 0 || user.roleList.some(s => f.roles.includes(s.name))),
              },
              menuItems: menuItem.filter(f => f.roles.length === 0 || user.roleList.some(s => f.roles.includes(s.name))),
            });
          }
        } else {
          this.setState({
            authorized: 2,
          });
        }
      });
    }
  }

  onLogin(data, callBack) {
    Service.User.Login(data).then(response => {
      if (response && response.token) {
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(response));

        this.setState({ authorized: 1 });
        window.location.reload();

        callBack(true);
      } else {
        this.setState({ authorized: 2 });
        callBack(false);
      }
    });
  }

  onLogout(e) {
    e.preventDefault();

    Service.User.Logout();
    this.setState({
      authorized: 2,
      menu: null,
      menuItems: [],
    });
  }

  render() {
    const { authorized, menu, menuItems } = this.state;
    const { children } = this.props;
    return (
      <Context.Provider
        value={{
          authorized,
          menu,
          menuItems,
          onLogin: this.onLogin,
          onLogout: this.onLogout,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


const AuthRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ authorized, onLogout, menu }) => {
      let content = '';

      if (authorized === 1) {
        content = (
          <Route
            render={props => (
              <Layout topbar footer sidebar onLogout={onLogout} menu={menu}>
                <Component {...props} />
              </Layout>
            )}
            {...rest}
          />
        );
      } else if (authorized === 2) {
        content = <Redirect to="/login" />;
      }
      return content;
    }}
  </AuthConsumer>
);

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export { AuthConsumer, AuthProvider, AuthRoute };
