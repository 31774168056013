import { Loading } from 'react-loading-ui';
import { BaseConfig } from '../config/AppConfig';
import { Fetch } from '../components';


export const UserService = {
  /**
 * [LOGIN]
 * @param {object} data
 * @param {string} data.login
 * @param {string} data.password
 */
  Login(data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(BaseConfig.service.user.login, requestOptions);
  },


  /**
    * [LOGOUT]
    */
  Logout() {
    localStorage.removeItem('user');
    window.location.href = '/login';
  },
  RecoverPassword(data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(BaseConfig.service.user.recoverPassword, requestOptions);
  },

  /**
   * [RESETPASSWORD]
   * @param {object} data
   * @param {string} data.email
   * @param {string} data.password
   * @param {string} data.confirmPassword
   */
  ResetPassword(data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return Fetch(BaseConfig.service.user.resetPassword, requestOptions);
  },
  /**
    * [AUTHENTICATED]
    */
  Authenticated() {
    const requestOptions = {
      method: 'GET',
      headers: BaseConfig.utilities.authorizedHeader(),
    };

    Loading();
    return fetch(BaseConfig.service.user.authenticated, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return false;
      })
      .then(json => {
        Loading();
        return json;
      })
      .catch(() => {
        Loading();
        return false;
      });
  },
  Save() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeaderWithFormData(),
      body: '',
    };
  },
  List() {
    return {
      method: 'GET',
      headers: BaseConfig.utilities.authorizedHeader(),
    };
  },
  Delete() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  Detail() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  ListByFilter() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  Common() {
    return {
      method: 'GET',
      headers: BaseConfig.utilities.authorizedHeader(),
    };
  },
};
