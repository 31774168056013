/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { T } from 'react-translator-component';
import DataTable from 'react-data-table-component';
import Switch from 'react-switch';
import ValidForm from 'react-valid-form-component';
import useFetch from 'react-use-fetch-hooks';

import { Card, Confirm, Notify } from '../../components'; // Notify eklenecek.
import { Loading } from 'react-loading-ui';
import { Modal } from '../../components/Modal';
import { DataTableColumns } from '../../config/DataTableColumns';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';

export const Application = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});
  const [common, setCommon] = useState({});
  const [switchStatus, setSwitchStatus] = useState(true);
  const [title, setTitle] = useState(null);
  const [FList, LList] = useFetch(BaseConfig.service.application.list, Service.Application.List());
  const [FDetail, LDetail] = useFetch(BaseConfig.service.application.detail, Service.Application.Detail());
  const [FDelete, LDelete] = useFetch(BaseConfig.service.application.delete, Service.Application.Delete());
  const [FSave, LSave] = useFetch(BaseConfig.service.application.save, Service.Application.Save());

  const getList = () => {
    FList().then(response => {
      if (response && response.error === false) {
        setData(response.data);
        setCommon(response.common);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const getDetail = id => {
    Loading();
    FDetail({ Id: id }).then(response => {
      if (response && response.error === false) {
        setDetail(response.data);
        setSwitchStatus(response.data.status);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    });
  };

  const onDetail = id => {
    setTitle(false);
    getDetail(id);
    setModal(true);

    setDetail({ ...detail, id });
  };

  const getDelete = id => {
    Loading();
    FDelete({ Id: id }).then(response => {
      if (response && response.error === false) {
        Notify.success(T('İşlem başarıyla gerçekleşti.'));
        getList();
      } else {
        Notify.error(T('Bir hata meydana geldi!'));
      }
      Loading();
    });
  };

  const onDelete = id => {
    Confirm({
      title: T('Emin misiniz?'),
      type: { warning: true },
      description: T('Bu uygulama kaldırılacaktır.'),
      onConfirm: () => {
        getDelete(id);
      },
      onCancel: () => {},
    });
  };

  const getSave = formData => {
    Loading();
    FSave(formData).then(response => {
      if (response && response.error === false) {
        getList();
        Notify.success(T('İşlem başarıyla tamamlandı!'));
        setModal(false);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    });
  };

  const onSave = (form, formData, valid) => {
    if (valid && formData) {
      formData.status = switchStatus;
      getSave(formData);
    }
  };


  const addNew = () => {
    setTitle(true);
    setDetail({
      id: 0,
      name: '',
      description: '',
      status: false,
    });
    setSwitchStatus(true);
    setModal(true);
  };


  return (
    <>
      <div className="row mb-5">
      </div>
      <Card
        Title={T('Uygulama')}
        Description=""
        Logo="sdf"
        className="ac-lead-list__card"
      >
        { BaseConfig.utilities.isUserManagerManagement() && (
          <div className="col-md-12 justify-content-end text-right">
            <button onClick={() => addNew()} type="button" className="btn btn-dark btn-sm">{T('Yeni Ekle')}</button>
          </div>
        )}
        <div className="col-md-12">
          <DataTable
            columns={DataTableColumns.application(onDetail, onDelete)}
            data={data}
            pagination
            defaultSortField="id"
            defaultSortAsc={false}
            noHeader
          />
        </div>
      </Card>

      <Modal
        Title={title ? T('Yeni Uygulama') : T('Uygulamayı Düzenle')}
        onClose={() => setModal(false)}
        Show={modal}
      >
        <ValidForm
          className="form-horizontal m-t-10"
          nosubmit
          onSubmit={(form, formData, valid) => onSave(form, formData, valid)}
          data={detail}
        >
          <input
            className="form-control"
            type="hidden"
            name="id"
            id="id"
            placeholder={T('id')}
          />
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
             {T('Uygulama Adı')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                required
                placeholder={T('Uygulama Adı')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
                />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Açıklama')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="description"
                id="description"
                placeholder={T('Açıklama')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
                />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Url')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="url"
                id="url"
                placeholder={T('https://usermanagement.trabilisim.com')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
                />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Durum')}
            </label>
            <div className="col-md-6">
              <Switch disabled={!BaseConfig.utilities.isUserManagerManagement()} onChange={() => setSwitchStatus(!switchStatus)} checked={switchStatus} />
            </div>
          </div>
          { BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-sm-12 text-right">
                <button className="btn btn-success w-md" type="submit">

                  {T('Kaydet')}
                </button>
              </div>
            </div>
          )}
        </ValidForm>
      </Modal>


    </>
  );
};
