import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';

export class Validation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidUpdate() {
    const { Value, Check } = this.props;
    const { show } = this.state;

    if (Check) {
      if (!show && (!Value || Value === -1)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          show: true,
        });
      } else if (show && (Value && Value !== -1)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          show: false,
        });
      }
    }
  }

  render() {
    const { Alert } = this.props;
    const { show } = this.state;
    return (show ? <span className="invalid-field-warning">{Alert}</span> : '');
  }
}

Validation.propTypes = {
  Alert: PropTypes.string,
  Value: PropTypes.any.isRequired,
  Check: PropTypes.bool.isRequired,
};

Validation.defaultProps = {
  Alert: T('Bu alan gereklidir.'),
};
