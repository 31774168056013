/* eslint-disable quote-props */
module.exports = {
  'Add New': 'Yeni Ekle',
  'Application': 'Uygulama',
  'Dashboard': 'Anasayfa',
  'Corporation': 'Şirket',
  'Email Template': 'E-posta Şablonu',
  'Home': 'Anasayfa',
  'Logout': 'Çıkış Yap',
  'Role': 'Rol',
  'TRA Information Technologies': 'TRA Bilgi Teknolojileri',
  'Title': 'Unvan',
  'Department': 'Departman',
  'User Role': 'Kullanıcı Rolü',
  'User': 'Kullanıcı',
  'Welcome': 'Hoşgeldiniz',
  'Action': 'Faaliyet',
  'Active': 'Aktif',
  'Address': 'Adres',
  'Birth Date': 'Doğum Tarihi',
  'Country Code': 'Ülke Kodu',
  'Date of Recruitment': 'İşe Alım Tarihi',
  'Delete': 'Sil',
  'Description': 'Açıklama',
  'Edit Application': 'Uygulamayı Düzenle',
  'Edit User': 'Kullanıcıyı Düzenle',
  'Edit Title': 'Unvanı Düzenle',
  'Edit Corporation': 'Şirketi Düzenle',
  'Edit Role': 'Rolü Düzenle',
  'Edit Department': 'Departmanı Düzenle',
  'Edit User Role': 'Kullanıcı Rolünü Düzenle',
  'Edit': 'Düzenle',
  'Email': 'E-posta',
  'External Phone': 'Harici Telefon',
  'First Name': 'İsim',
  'Id': 'Id',
  'Internal Phone': 'Dahili Telefon',
  'Last Name': 'Soyisim',
  'Name': 'Ad',
  'Passive': 'Pasif',
  'Password': 'Şifre',
  'Save': 'Kaydet',
  'Status': 'Durum',
  'Title Name': 'Unvan Adı',
  'Department Name': 'Departman Adı',
  'User Name': 'Kullanıcı Adı',
  'Are you sure?': 'Emin misiniz?',
  'This title will be removed': 'Bu unvan kaldırılacaktır.',
  'This appliaction will be removed': 'Bu uygulama kaldırılacaktır.',
  'This user will be removed': 'Bu kullanıcı kaldırılacaktır.',
  'This corporation will be removed': 'Bu şirket kaldırılacaktır.',
  'This department will be removed': 'Bu departman kaldırılacaktır.',
  'This role will be removed': 'Bu rol kaldırılacaktır.',
  'This user role will be removed': 'Bu kullanıcı rolü kaldırılacaktır.',
  'Role Name': 'Kullanıcı Rolü',
  'Corporation Name': 'Şirket Adı',
  'Application Name': 'Uygulama Adı',
  'Type': 'Tip',
  'Subject': 'Konu',
  'Html': 'Html',
  'Comment': 'Yorum',
  'Language': 'Dil',
  'Process completed successfully! ': 'İşlem başarıyla tamamlandı!',
  'New User': 'Yeni Kullanıcı',
  'New Application': 'Yeni Uygulama',
  'New Role': 'Yeni Rol',
  'New Title': 'Yeni Unvan',
  'New User Role': 'Yeni Kullanıcı Rolü',
  'New Corporation': 'Yeni Şirket',
  'New Department': 'Yeni Departman',
  'Filter': 'Filtre',
  'Your password has been successfully updated': 'Şifreniz başarıyla güncellendi.',
  'User Permissions': 'Kullanıcı izinleri',
  'Permissions': 'İzinler',
  'Permission Name': 'Yetki Adı',
  'New User Permission': 'Yeni Kullanıcı Yetkisi',
  'Edit User Permission': 'Kullanıcı Yetkisini Düzenle',
  'Permission is not created because this permission already exists! Please activate it.': 'Yetki oluşturulamadı çünkü böyle bir yetki halihazırda var. Lütfen aktif hale getiriniz.',
  'Permission is not updated because this permission already exists! Please activate it.': 'Yetki güncellenemedi çünkü böyle bir yetki halihazırda var. Lütfen aktif hale getiriniz.',
  'Invalid permission!': 'Hatalı izin girişi!',
  'Create Password': 'Şifre Oluştur',
  'Create': 'Oluştur',
  'Reset Password': 'Şifre Sıfırla',
  'Reset': 'Sıfırla',
  'All areas are required!': 'Tüm alanlar zorunludur!',
  'Your password has been successfully updated.': 'Şifreniz başarıyla güncellenmiştir.',
};
