import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Notify = {
  error: message => {
    Notify.invoke('error', Array.isArray(message) ? message[0] : message);
  },
  success: message => {
    Notify.invoke('success', Array.isArray(message) ? message[0] : message);
  },
  invoke: (type, text) => {
    toast[type](text, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  },
};
