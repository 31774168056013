import { Loading } from 'react-loading-ui';
import { BaseConfig } from '../config/AppConfig';
import { Fetch } from '../components';


export const WorkFlowService = {
  CreateAnnualLeaveByUserId(data) {
    const requestOptions = {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: JSON.stringify(data),
    };
    Loading();
    return Fetch(BaseConfig.service.workflow.createAnnualLeave, requestOptions).then(response => {
      Loading();
      return response;
    });
  },
};
