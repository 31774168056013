import { BaseConfig } from '../config/AppConfig';

export const UserRoleService = {
  Save() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  List() {
    return {
      method: 'GET',
      headers: BaseConfig.utilities.authorizedHeader(),
    };
  },
  Delete() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  Detail() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  ListByFilter() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
  SelectListByUser() {
    return {
      method: 'POST',
      headers: BaseConfig.utilities.authorizedHeader(),
      body: '',
    };
  },
};
