import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as History } from 'history';
import { Translator } from 'react-translator-component';
import { ToastContainer } from 'react-toastify';
import 'react-use-fetch-hooks/dist/index.css';
import './App.css';
import './config/Utilities';
import './config/Enums';
import {
  Dashboard,
  ErrorPage,
  Login,
  RecoverPassword,
  NewPassword,
  Application,
  User,
  Corporation,
  Title,
  Department,
  Role,
  UserRole,
  Permission,
  ForgotPassword,
  EmailTemplate,
} from './views';
import { AuthProvider, AuthRoute, AuthConsumer } from './contexts/Authentication';

const views = {
  Dashboard,
  Application,
  User,
  Title,
  Corporation,
  Department,
  Role,
  UserRole,
  Permission,
};

function App() {
  return (
    <HttpsRedirect>
      <Router history={History()}>
        <AuthProvider>
          <AuthConsumer>
            {({ menuItems, authorized }) => (
              <Translator>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/recoverpassword" component={RecoverPassword} />
                  <Route exact path="/newpassword/:token" component={NewPassword} />
                  <Route exact path="/forgotpassword/:token" component={ForgotPassword}/>

                  {/* AUTH ROUTE */}
                  {menuItems.map(item => (
                    <AuthRoute key={item.path} exact path={item.path.split(',')} component={views[item.component]} />
                  ))}

                  {/* Error Pages */}
                  <Route exact path="/error/:error" component={ErrorPage} />
                  {menuItems.length !== 0 && <Route component={ErrorPage} />}
                  {(authorized === 2 && document.location.pathname !== '/login') && <Redirect to="/login" />}
                </Switch>
              </Translator>
            )}
          </AuthConsumer>

        </AuthProvider>
        <ToastContainer />
      </Router>
    </HttpsRedirect>
  );
}

export default App;
