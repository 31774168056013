import React, { useEffect, useState } from 'react';
import { T } from 'react-translator-component';
import { Link } from 'react-router-dom';
import useFetch from 'react-use-fetch-hooks';
import { Loading } from 'react-loading-ui';
import { BreadCrumb, Card } from '../../components';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';

export const Dashboard = () =>
// const widgetColors = {
//   0: { bg: 'info', text: 'white' },
//   1: { bg: 'danger', text: 'white' },
//   2: { bg: 'primary', text: 'white' },
//   3: { bg: 'warning', text: 'white' },
//   4: { bg: 'success', text: 'white' },
//   5: { bg: 'secondary', text: 'white' },
// };
// const [applications, setApplications] = useState();

// const [FCommon, LComomn] = useFetch(BaseConfig.service.dashboard.commons, Service.Dashboard.Commons());


// useEffect(() => {
//   getCommons();
// }, []);

// const getCommons = () => {
//   Loading();
//   FCommon().then(response => {
//     if (response && response.error === false) {
//       setApplications(response.data.applications);
//     }
//     Loading();
//   });
// };

  (
    <>
      <BreadCrumb
        Description={`${T('Hoşgeldiniz')} ${BaseConfig.utilities.user().name}`}
      />
      <Card
        Title={T('Dashboard')}
        Description=""
        Logo="sdf"
        className="ac-lead-list__card"
      >
        {/* <div className="row">
          {
            applications && applications.map((item, index) => (
              <div className="col-xl-3 col-md-4 col-12">
                <div className={`card mini-stat bg-${widgetColors[index % 6].bg} text-${widgetColors[index % 6].text}`}>
                  <div className="card-body">
                    <div className="mb-4">
                      <div className="float-left mini-stat-img mr-4">
                        <i className="mdi mdi-layers" />
                      </div>
                      <h5 className="font-16 text-uppercase mt-0 text-white-50">{T(item.name)}</h5>
                      <h4 className="font-500">
                        {item.description}
                      </h4>
                    </div>
                    <div className="pt-2">
                      <div className="float-right cursor">
                        <a href={item.url} target="_blank" className="text-white-50"><i className="mdi mdi-arrow-right h5" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div> */}
      </Card>
    </>
  );
