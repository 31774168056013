/* eslint-disable global-require */
import { Config } from 'react-translator-component';
import { BaseConfig } from './AppConfig';

/**
 * Translator Settings
 */
Config.default = 'tr';

Config.list = {
  tr: {
    text: 'Türkçe',
    icon: require('../assets/locale/flags/tr.svg'),
    file: require('../assets/locale/tr.js'),
  },
  // cn: {
  //   text: 'Chinese',
  //   icon: require('../assets/locale/flags/cn.svg'),
  //   file: require('../assets/locale/cn.js'),
  // },
  // tr: {
  //   text: 'Turkish',
  //   icon: require('../assets/locale/flags/tr.svg'),
  //   file: require('../assets/locale/tr.js'),
  // },
};

/**
 * JS Error Catch
 */
if (!(BaseConfig.config.debug() === 'demo' || BaseConfig.config.debug() === 'debug')) {
  window.onerror = (msg, src, lineno, colno, error) => {
    // eslint-disable-next-line no-console
    console.log(
      'Application Error',
      {
        Message: msg,
        Src: src,
        Line: lineno,
        Column: colno,
        Error: error,
      },
    );

    window.location.href = '/error/500';

    return false;
  };
}
