const rolesRouting = {
  home: [],
  application: ['UserManagementManager', 'UserManagementAdmin'],
  user: ['UserManagementManager', 'UserManagementAdmin'],
  title: ['UserManagementManager', 'UserManagementAdmin'],
  corporation: ['UserManagementManager', 'UserManagementAdmin'],
  department: ['UserManagementManager', 'UserManagementAdmin'],
  role: ['UserManagementManager', 'UserManagementAdmin'],
  userRole: ['UserManagementManager', 'UserManagementAdmin'],
  emailTemplate: ['UserManagementManager', 'UserManagementAdmin'],
  permission: ['UserManagementManager', 'UserManagementAdmin'],
};

export const Menu = {
  main: [{
    id: 1,
    title: 'Anasayfa',
    path: '/home,/dashboard,/',
    class: 'fas fa-home',
    component: 'Dashboard',
    groupTitle: 'Main',
    roles: rolesRouting.home,
  },
  {
    id: 3,
    title: 'Kullanıcı',
    path: '/user',
    class: 'fas fa-user',
    component: 'User',
    groupTitle: 'Main',
    roles: rolesRouting.user,
  },
  {
    id: 2,
    title: 'Uygulama',
    path: '/application',
    class: 'fas fa-server',
    component: 'Application',
    groupTitle: 'Main',
    roles: rolesRouting.application,
  },
  {
    id: 4,
    title: 'Unvan',
    path: '/title',
    class: 'fas fa-tags',
    component: 'Title',
    groupTitle: 'Main',
    roles: rolesRouting.title,
  },
  {
    id: 5,
    title: 'Şirket',
    path: '/corporation',
    class: 'fas fa-building',
    component: 'Corporation',
    groupTitle: 'Main',
    roles: rolesRouting.corporation,
  },
  {
    id: 6,
    title: 'Departman',
    path: '/department',
    class: 'fas fa-mortar-board',
    component: 'Department',
    groupTitle: 'Main',
    roles: rolesRouting.department,
  },
  {
    id: 7,
    title: 'Rol',
    path: '/role',
    class: 'fas fa-globe',
    component: 'Role',
    groupTitle: 'Main',
    roles: rolesRouting.role,
  },
  {
    id: 8,
    title: 'Kullanıcı Rolü',
    path: '/userrole',
    class: 'fas fa-user-circle',
    component: 'UserRole',
    groupTitle: 'Main',
    roles: rolesRouting.userRole,
  },
  {
    id: 9,
    title: 'Kullanıcı İzinleri',
    path: '/permission',
    class: 'fas fa-sitemap',
    component: 'Permission',
    groupTitle: 'Main',
    roles: rolesRouting.permission,
  },
  // {
  //   id: 9,
  //   title: 'E-posta Şablonu',
  //   path: '/emailtemplate,/emailtemplate,/',
  //   class: 'fas fa-envelope',
  //   component: 'EmailTemplate',
  //   groupTitle: 'Main',
  //   roles: rolesRouting.emailTemplate,
  // },
  ],
  menuItems: [{
    id: 1,
    title: 'Home',
    path: '/home,/dashboard,/',
    class: 'fas fa-home',
    component: 'Dashboard',
    groupTitle: 'Main',
    roles: rolesRouting.home,
  },
  {
    id: 3,
    title: 'User',
    path: '/user',
    class: 'fas fa-user',
    component: 'User',
    groupTitle: 'Main',
    roles: rolesRouting.user,
  },
  {
    id: 2,
    title: 'Application',
    path: '/application',
    class: 'fas fa-server',
    component: 'Application',
    groupTitle: 'Main',
    roles: rolesRouting.application,
  },
  {
    id: 4,
    title: 'Title',
    path: '/title',
    class: 'fas fa-tags',
    component: 'Title',
    groupTitle: 'Main',
    roles: rolesRouting.title,
  },
  {
    id: 5,
    title: 'Corporation',
    path: '/corporation',
    class: 'fas fa-building',
    component: 'Corporation',
    groupTitle: 'Main',
    roles: rolesRouting.corporation,
  },
  {
    id: 6,
    title: 'Department',
    path: '/department',
    class: 'fas fa-user',
    component: 'Department',
    groupTitle: 'Main',
    roles: rolesRouting.department,
  },
  {
    id: 7,
    title: 'Role',
    path: '/role',
    class: 'fas fa-mortar-board',
    component: 'Role',
    groupTitle: 'Main',
    roles: rolesRouting.role,
  },
  {
    id: 8,
    title: 'User Role',
    path: '/userrole',
    class: 'fas fa-user-circle',
    component: 'UserRole',
    groupTitle: 'Main',
    roles: rolesRouting.userRole,
  },
  {
    id: 9,
    title: 'İzinler',
    path: '/permission',
    class: 'fas fa-sitemap',
    component: 'Permission',
    groupTitle: 'Main',
    roles: rolesRouting.permission,
  },
  // {
  //   id: 8,
  //   title: 'Email Template',
  //   path: '/emailtemplate,/emailtemplate,/',
  //   class: 'fas fa-envelope',
  //   component: 'EmailTemplate',
  //   groupTitle: 'Main',
  //   roles: rolesRouting.emailTemplate,
  // },
  ],
};
