import { BaseConfig } from '../config/AppConfig';

export const DashboardService = {
  Commons() {
    return {
      method: 'GET',
      headers: BaseConfig.utilities.authorizedHeader(),
    };
  },
};
