import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';

export const BreadCrumb = props => {
  const { Title, Description, Name, SubTitle, SubUrl } = props;


  return (
    <>
      <div className="page-title-box">
        <div className="row align-items-center">
          <div className="col-sm-10">
            <h4 className="page-title">{T(Title)}</h4>
            <ol className="breadcrumb">
              {Description && <li className="breadcrumb-item">{T(Description)}</li>}
              {Name && (
                <>
                  <li className="breadcrumb-item"><Link to="/home">{T('Anasayfa')}</Link></li>
                  {SubTitle && <li className="breadcrumb-item"><Link to={SubUrl}>{`(${SubTitle})`}</Link></li>}
                  <li className="breadcrumb-item active">{T(Name)}</li>
                </>
              )}
            </ol>
          </div>

        </div>
      </div>
    </>
  );
};

BreadCrumb.propTypes = {
  Title: PropTypes.string,
  Description: PropTypes.string,
  Name: PropTypes.array,
  SubTitle: PropTypes.string,
  SubUrl: PropTypes.string,
};

BreadCrumb.defaultProps = {
  Title: null,
  Description: null,
  Name: null,
  SubTitle: null,
  SubUrl: '',
};
