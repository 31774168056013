/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { T } from 'react-translator-component';
import DataTable from 'react-data-table-component';
import Switch from 'react-switch';
import ValidForm from 'react-valid-form-component';
import useFetch from 'react-use-fetch-hooks';
import Select from 'react-select';

import { Card, Confirm, Notify } from '../../components'; // Notify eklenecek.
import { Loading } from 'react-loading-ui';
import { Modal } from '../../components/Modal';
import { DataTableColumns } from '../../config/DataTableColumns';
import { BaseConfig } from '../../config/AppConfig';
import { Service } from '../../services';

export const Role = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});
  const [common, setCommon] = useState(0);
  const [switchStatus, setSwitchStatus] = useState(true);
  const [selected, setSelected] = useState('');
  const [title, setTitle] = useState(null);
  const [FList, LList] = useFetch(BaseConfig.service.role.list, Service.Role.List());
  const [FDetail, LDetail] = useFetch(BaseConfig.service.role.detail, Service.Role.Detail());
  const [FDelete, LDelete] = useFetch(BaseConfig.service.role.delete, Service.Role.Delete());
  const [FSave, LSave] = useFetch(BaseConfig.service.role.save, Service.Role.Save());
  const [isModalSubmitted, setIsModalSubmitted] = useState(false);

  const getList = () => {
    FList().then(response => {
      if (response && response.error === false) {
        setData(response.data);
        setCommon(response.common);
      }
    });
  };
  useEffect(() => {
    getList();
  }, []);

  const getDetail = id => {
    Loading();
    FDetail({ Id: id }).then(response => {
      if (response && response.error === false) {
        setSelected(common.application.filter(f => f.value === response.data.applicationId)[0]);
        setDetail({ ...response.data, applicationSelected: selected });
        setSwitchStatus(response.data.status);
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
      Loading();
    });
  };

  const onDetail = id => {
    setTitle(false);
    getDetail(id);
    setModal(true);
    setDetail({ ...detail, id });
  };

  const getDelete = id => {
    Loading();
    FDelete({ Id: id }).then(response => {
      if (response && response.error === false) {
        Notify.success(T('İşlem başarıyla gerçekleşti.'));
        getList();
      } else {
        Notify.error(T('Bir hata meydana geldi.'));
      }
      Loading();
    });
  };

  const onDelete = id =>
    Confirm({
      title: T('Emin misiniz?'),
      type: { warning: true },
      description: T('Bu rol kaldırılacaktır.'),
      onConfirm: () => {
        getDelete(id);
      },
      onCancel: () => {},
    });


  const getSave = formData => {
    Loading();
    FSave(formData).then(response => {
      if (response && response.error === false) {
        getList();
        Notify.success(T('İşlem başarıyla tamamlandı!'));
        setModal(false);
      } else {
        Notify.error(T(response.message));
      }
      Loading();
    });
  };

  const onSave = (form, formData, valid) => {
    setIsModalSubmitted(true);

    if (valid && formData && selected) {
      formData.status = switchStatus;
      getSave(formData);
    }
  };

  const addNew = () => {
    setTitle(true);
    setDetail({
      id: 0,
      name: '',
      description: '',
      applicationId: 0,
      status: false,
      userId: 0,
    });
    setSelected('');
    setSwitchStatus(true);
    setModal(true);
    setIsModalSubmitted(false);
  };

  const changeSelect = opt => {
    setSelected(opt, opt && opt.value);
  };

  const closeModal = () => {
    setIsModalSubmitted(false);
    setModal(false);
  }

  return (
    <>
      <Card
        Title={T('Rol')}
        Description=""
        Logo="sdf"
        className="ac-lead-list__card"
      >
        {
          BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row mb-3 justify-content-end">
              <div className="col-md-3 mt-3 text-right">
                <button onClick={() => addNew()} type="button" className="btn btn-dark btn-sm">{T('Yeni Ekle')}</button>
              </div>
            </div>
          )
        }
        <div className="col-md-12">
          <DataTable
            columns={DataTableColumns.role(onDetail, onDelete)}
            data={data}
            pagination
            defaultSortField="id"
            defaultSortAsc={false}
            noHeader
          />
        </div>
      </Card>
      <Modal
        Title={title ? T('Yeni Role') : T('Rolü Düzenle')}
        onClose={() => closeModal()}
        Show={modal}
      >

        <ValidForm
          className="form-horizontal m-t-10"
          nosubmit
          onSubmit={(form, formData, valid) => onSave(form, formData, valid)}
          data={detail}
        >

          <input
            className="form-control"
            type="hidden"
            name="id"
            id="id"
            placeholder={T('id')}
          />

          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Ad')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                placeholder={T('Ad')}
                required
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Açıklama')}
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                name="description"
                id="description"
                placeholder={T('Açıklama')}
                disabled={!BaseConfig.utilities.isUserManagerManagement()}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">
              {T('Uygulama Adı')}
            </label>
            <div className="col-md-6">
              <Select
                name="applicationId"
                inputId="applicationId"
                options={common.application}
                className="react-select-valid"
                value={selected}
                placeholder={isModalSubmitted ? (selected ? T("Seçiniz...") : <span className="text-danger">{T("Zorunlu...")}</span> ) : T("Seçiniz...") }
                onChange={opt => changeSelect(opt)}
                isDisabled={!BaseConfig.utilities.isUserManagerManagement()}
              />
            </div>
          </div>
          <div className="col-md-12 row mb-3">
            <label className="control-label col-md-2 mt-2 ml-3">{T('Durum')}</label>
            <div className="col-md-6">
              <Switch disabled={!BaseConfig.utilities.isUserManagerManagement()} onChange={() => setSwitchStatus(!switchStatus)} checked={switchStatus} />
            </div>

          </div>
          { BaseConfig.utilities.isUserManagerManagement() && (
            <div className="row">
              <div className="col-sm-12 text-right">
                <button className="btn btn-success w-md" type="submit">
                  {T('Kaydet')}
                </button>
              </div>
            </div>
          )}
        </ValidForm>
      </Modal>
    </>
  );
};
