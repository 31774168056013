export const PermissionTypes = {
  Settings: 1,
  Agreements: 2,
  AllForms: 3,
  Payments: 4,
  AllExpense: 5,
  AllAnnualLeave: 6,
  AllPurchasing: 7,
  Chart: 8,
};

export const WorkflowUserRole = {
  Manager: 'WorkflowManager',
  User: 'WorkflowUser',
  HumanResources: 'WorkflowHumanResources',
  Finance: 'WorkflowFinance',
  Purchasing: 'WorkflowPurchasing',
};
