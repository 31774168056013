import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { T, LanguageList } from 'react-translator-component';
import ValidForm from 'react-valid-form-component';
import { AuthConsumer } from '../../contexts/Authentication';


export const Login = () => {
  const [Error, setError] = useState(false);

  const callBack = isAuth => {
    setError(!isAuth);
  };

  return (
    <AuthConsumer>
      {({ authorized, onLogin }) => (
        (authorized === 1) ?
          <Redirect to="/home" />
          : (
            <div className="wrapper-page">
              <div className="card account-card mx-3">
                <div className="bg-warning p-4 text-white text-center position-relative">
                  <h4 className="font-20 m-b-5">{process.env.REACT_APP_TITLE}</h4>
                  <p className="text-white-50 mb-4">{process.env.REACT_APP_SUBTITLE}</p>
                  <Link to="/login" className="logo logo-admin"><img src={process.env.REACT_APP_LOGIN_LOGO} height="19" alt="logo" /></Link>
                </div>
                <div className="account-card-content">
                  {(Error) ?
                    (
                      <div className="alert alert-danger alert-dismissible fade show mt-3" role="alert">
                        <strong className="mr-2">{T('Yetkisiz!')}</strong>
                        {T('Geçersiz giriş bilgileri!')}
                      </div>
                    ) : ''}
                  <ValidForm className="form-horizontal m-t-30" nosubmit onSubmit={(form, data) => onLogin(data, callBack)}>
                    <div className="form-group">
                      <label htmlFor="email">{T('E-posta')}</label>
                      <input type="email" className="form-control" id="email" name="email" placeholder={T('E-posta Giriniz')} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">{T('Şifre')}</label>
                      <input type="password" className="form-control" id="password" name="password" placeholder={T('Şifre giriniz')} required />
                    </div>
                    <div className="form-group row m-t-20">
                      <div className="col-sm-6">
                        <LanguageList Theme="Dropdown" />
                      </div>
                      <div className="col-sm-6 text-right">
                        <button type="submit" className="btn btn-warning w-md waves-effect waves-light">{T('Giriş Yap')}</button>
                      </div>
                    </div>
                    <div className="form-group m-t-10 mb-0 row">
                      <div className="col-12 m-t-20">
                        <Link to="/recoverpassword">
                          <i className="mdi mdi-lock mr-2" />
                          <span>{T('Şifrenizi mi unuttunuz?')}</span>
                        </Link>
                      </div>
                    </div>
                  </ValidForm>
                </div>
              </div>
              <div className="m-t-40 text-center">
                <p>{`© ${new Date().getFullYear()} ${T('TRA Information Technologies')}`}</p>
              </div>
            </div>
          )
      )}
    </AuthConsumer>
  );
};
