import React from 'react';
import PropTypes from 'prop-types';
import { T } from 'react-translator-component';

export const Card = props => {
  const { className, Title, Description, Tools, children, Logo } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className={`card ${className}`}>
          <div className="card-body">
            <div className="card-title">
              {Title && (
              <h4 className="mt-0 header-title">
                {Logo && <i className={`fas ${Logo} mr-2`} />}
                {T(Title)}
              </h4>
              )}
              {Description && <p className="text-muted m-b-30">{T(Description)}</p>}
            </div>
            <div className="card-tool">
              {Tools}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  Title: PropTypes.string,
  Description: PropTypes.string,
  Tools: PropTypes.node,
  children: PropTypes.node.isRequired,
  Logo: PropTypes.string,
};

Card.defaultProps = {
  className: '',
  Title: null,
  Description: null,
  Logo: null,
  Tools: '',
};
