/* eslint-disable no-extend-native */
// eslint-disable-next-line func-names
const format = function (f, s = ',') {
  let value = this;

  switch (f.toLowerCase()) {
    case 'c':
    case 'currency':
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
      break;
    default:
      break;
  }

  return value;
};

export const fromDDMMYYYYtoYYYYMMDD = dateObj => {
  // eslint-disable-next-line prefer-destructuring
  dateObj = dateObj.split(' ')[0];
  const dateParts = dateObj.split('.');
  return [
    dateParts[1],
    dateParts[0],
    dateParts[2],
  ].join('.');
};

export const toUnix = dateValue => {
  // 27.11.2020 00:00:00
  const splitDate = dateValue.split(' ')[0].split('.');
  const month = splitDate[1];
  const splitTime = dateValue.split(' ')[1].split(':');
  return new Date(splitDate[2], (month - 1), splitDate[0], splitTime[0], splitTime[1], splitTime[2]);
};

export const toYYmmDD = dateObj => {
  const mm = dateObj.getMonth() + 1; // getMonth() is zero-based
  const dd = dateObj.getDate();

  return [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    dateObj.getFullYear(),
  ].join('.');
};

// eslint-disable-next-line func-names
const capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toCapitalizeCase = capitalize;
String.prototype.format = format;
Number.prototype.format = format;


export const compareDates = (first, second) => {
  first.setHours(9, 0, 0, 0);
  second.setHours(9, 0, 0, 0);
  if (first.getTime() < second.getTime()) {
    return -1;
  } if (first.getTime() === second.getTime()) {
    return 0;
  }
  return 1;
};

Object.defineProperty(Date.prototype, 'normalizeHours', {
  value: function normalizeHours() {
    return this.setHours(9, 0, 0, 0);
  },
  writable: true,
  configurable: true,
});

Object.defineProperty(String.prototype, 'stringToNormalizedDate', {
  value: function stringToDate() {
    return (new Date(this.split('T')[0])).setHours(9, 0, 0, 0);
  },
  writable: true,
  configurable: true,
});
