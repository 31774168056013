import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery';
import './Leftsidebar.css';
import { T } from 'react-translator-component';

let activeTab = window.location.pathname;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: activeTab.toLowerCase(),
    };
    this.setActiveTab = this.setActiveTab.bind(this);
  }


  componentDidMount() {
    if ($(window).width() < 1025) {
      $('body').addClass('enlarged');
    } else {
      $('body').removeClass('enlarged');
    }

    $('.button-menu-mobile').on('click', event => {
      event.preventDefault();
      $('body').toggleClass('enlarged');
    });
  }

  setActiveTab(tab) {
    this.setState({ Tab: tab });
    activeTab = tab;
    if ($(window).width() < 1025) {
      $('body').addClass('enlarged');
    }
  }

  render() {
    const { MenuItems } = this.props;
    const { Tab } = this.state;
    return (
      <div className="left side-menu">
        <Scrollbars className="Scrollbars">
          <div>
            <div id="sidebar-menu">
              <ul className="metismenu" id="side-menu">
                {
                  Object.keys(MenuItems).map(group => (
                    <React.Fragment key={group}>
                      {group !== 'Main' && <li className="menu-title">{group}</li>}
                      {MenuItems[group].map(item => {
                        const path = item.path.split(',')[0];
                        return (
                          <li key={item.path}>
                            <Link to={path} className={Tab === path.toLowerCase() ? 'waves-effect active' : 'waves-effect'} title={T(item.title)} onClick={() => this.setActiveTab(path.toLowerCase())}>
                              <i className={item.class} />
                              <span className="ml-1 mr-1">{T(item.title)}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </React.Fragment>
                  ))
                }
              </ul>
            </div>
            <div className="clearfix" />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

SideBar.propTypes = {
  MenuItems: PropTypes.object,
};

SideBar.defaultProps = {
  MenuItems: null,
};

export default SideBar;
