export const BaseConfig = {
  config: {
    /**
   * [GENERAL]
   */
    // eslint-disable-next-line func-names, prefer-arrow-callback, no-unused-vars
    debug: () => {
      if (/.demo./.test(window.location.href)) {
        return 'demo';
        // eslint-disable-next-line func-names, prefer-arrow-callback, no-unused-vars
      } if (/param/.test(function (param) { })) {
        return 'debug';
      }
      return 'release';
    },
  },
  api: {
    release: {
      user: 'https://makasuserservice.teragrup.com',
      workflow: 'https://makasservice.teragrup.com',
    },
    debug: {
      user: 'http://localhost:53448',
      workflow: 'http://localhost:57831',
    },
    demo: {
      user: 'https://usermanagement.demo.trabilisim.com',
      workflow: 'https://makas.demo.trabilisim.com',
    },
    user: url => BaseConfig.api[BaseConfig.config.debug()].user.concat(url),
    workflow: url => BaseConfig.api[BaseConfig.config.debug()].workflow.concat(url),
  },
  service: {
    user: {},
    application: {},
    corporation: {},
    role: {},
    title: {},
    department: {},
    userrole: {},
    dashboard: {},
    permission: {},
  },
  utilities: {
    authorizedHeader: () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {
        return { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` };
      }
      return { 'Content-Type': 'application/json' };
    },
    user: () => (JSON.parse(localStorage.getItem('user'))),
    userToken: () => (JSON.parse(localStorage.getItem('user')).token),
    isUserManagerManagement: () => JSON.parse(localStorage.getItem('user')).roleList.some(role => role.name === 'UserManagementManager'),
    dates: {
      report: () => {
        const reportDate = localStorage.getItem('reportDate');
        const date = new Date();
        date.setDate(date.getDate() - 1);

        return (JSON.parse(reportDate) || { beginDate: date, endDate: date });
      },
      dashboard: () => {
        const dashboardDate = localStorage.getItem('dashboardDate');
        const date = new Date();

        return (JSON.parse(dashboardDate) || { beginDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: date });
      },
    },
    authorizedHeaderWithFormData: () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {
        return { 'Content-Type': 'multipart/formdata', Authorization: `Bearer ${user.token}` };
      }
      return { 'Content-Type': 'multipart/formdata' };
    },
    RolesByPermission: {
      Manager: 1,
      HumanResources: 2122,
      Finance: 2123,
      Purchasing: 2124,
    },
  },
};

BaseConfig.service.user = {
  root: BaseConfig.api.user('/api'),
  login: BaseConfig.api.user('/user/login'),
  authenticated: BaseConfig.api.user('/user/authorized'),
  recoverPassword: BaseConfig.api.user('/user/recoverpassword'),
  resetPassword: BaseConfig.api.user('/user/resetPassword'),
  list: BaseConfig.api.user('/user/list'),
  listByFilter: BaseConfig.api.user('/user/list'),
  detail: BaseConfig.api.user('/user/detail'),
  delete: BaseConfig.api.user('/user/delete'),
  save: BaseConfig.api.user('/user/save'),
  common: BaseConfig.api.user('/user/getCommon'),
};
BaseConfig.service.application = {
  list: BaseConfig.api.user('/application/list'),
  detail: BaseConfig.api.user('/application/detail'),
  delete: BaseConfig.api.user('/application/delete'),
  save: BaseConfig.api.user('/application/save'),
};
BaseConfig.service.corporation = {
  list: BaseConfig.api.user('/corporation/list'),
  detail: BaseConfig.api.user('/corporation/detail'),
  delete: BaseConfig.api.user('/corporation/delete'),
  save: BaseConfig.api.user('/corporation/save'),
  selectList: BaseConfig.api.user('/corporation/selectlist'),
};
BaseConfig.service.role = {
  list: BaseConfig.api.user('/role/list'),
  detail: BaseConfig.api.user('/role/detail'),
  delete: BaseConfig.api.user('/role/delete'),
  save: BaseConfig.api.user('/role/save'),
};
BaseConfig.service.title = {
  list: BaseConfig.api.user('/title/list'),
  detail: BaseConfig.api.user('/title/detail'),
  delete: BaseConfig.api.user('/title/delete'),
  save: BaseConfig.api.user('/title/save'),
  selectList: BaseConfig.api.user('/title/selectList'),
};
BaseConfig.service.department = {
  list: BaseConfig.api.user('/department/list'),
  detail: BaseConfig.api.user('/department/detail'),
  delete: BaseConfig.api.user('/department/delete'),
  save: BaseConfig.api.user('/department/save'),
  selectList: BaseConfig.api.user('/department/selectList'),
  selectListByCorporationId: BaseConfig.api.user('/department/selectListByCorporationId'),
  getDepartmentListByCorporation: BaseConfig.api.user('/department/getDepartmentListByCorporation'),
};
BaseConfig.service.userrole = {
  list: BaseConfig.api.user('/userrole/list'),
  detail: BaseConfig.api.user('/userrole/detail'),
  delete: BaseConfig.api.user('/userrole/delete'),
  save: BaseConfig.api.user('/userrole/save'),
  selectList: BaseConfig.api.user('/userrole/selectlist'),
  selectListByUser: BaseConfig.api.user('/userrole/selectListByUser'),
};
BaseConfig.service.dashboard = {
  commons: BaseConfig.api.user('/dashboard/commons'),
};
BaseConfig.service.permission = {
  list: BaseConfig.api.user('/permission/list'),
  detail: BaseConfig.api.user('/permission/detail'),
  delete: BaseConfig.api.user('/permission/delete'),
  save: BaseConfig.api.user('/permission/save'),
  selectList: BaseConfig.api.user('/permission/selectlist'),
};


BaseConfig.service.workflow = {
  createAnnualLeave: BaseConfig.api.workflow('/api/AnnualLeave/CreateAnnualLeaveValue'),
};
