import { ApplicationService } from './ApplicationService';
import { CorporationService } from './CorporationService';
import { RoleService } from './RoleService';
import { UserService } from './UserService';
import { TitleService } from './TitleService';
import { DepartmentService } from './DepartmentService';
import { UserRoleService } from './UserRoleService';
import { DashboardService } from './DashboardService';
import { PermissionService } from './PermissionService';

export const Service = {
  User: UserService,
  Application: ApplicationService,
  Corporation: CorporationService,
  Role: RoleService,
  Title: TitleService,
  Department: DepartmentService,
  UserRole: UserRoleService,
  Dashboard: DashboardService,
  Permission: PermissionService,
};
